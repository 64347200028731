import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EventNotifierService, DashpivotEvent, EventTypes } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';

import { AccountUpgradeLimitModalContext } from './account-upgrade-limit-context.enum';
import { AccountUpgradeLimitComponent } from './account-upgrade-limit.component';
import { IUpgradeUserContext } from './upgrade-user-context.model';

@Injectable({
  providedIn: 'root',
})
export class AccountUpgradeLimitService {
  private ssoArticle = 'https://intercom.help/dashpivot/en/articles/8133878-how-to-setup-single-sign-on-sso';
  private dashpivotWorkspaceArticle =
    'https://intercom.help/dashpivot/en/articles/9205707-what-is-a-dashpivot-workspace';

  readonly userContext: Record<AccountUpgradeLimitModalContext, IUpgradeUserContext> = {
    [AccountUpgradeLimitModalContext.DashpivotVisitorUsers]: {
      title: 'Unlock Dashpivot Visitor Users',
      subtitle:
        'Contact us to upgrade your subscription plan and enable Dashpivot Visitor Users to be invited into your workspace.',
      imageSrc: '/assets/images/usage-limits/visitor-users.svg',
      imageAlt: 'three smiling construction workers',
      eventContext: 'Unlock Dashpivot Visitor Users Banner',
    },
    [AccountUpgradeLimitModalContext.SitematePaidUsers]: {
      title: 'Unlock Sitemate Paid Users',
      subtitle:
        'Contact us to upgrade your subscription plan and enable Sitemate Paid users to submit forms multiple times via the Sitemate mobile app.',
      imageSrc: '/assets/images/usage-limits/visitor-users.svg',
      imageAlt: 'three smiling construction workers',
      eventContext: 'Unlock Sitemate Paid Users Banner',
    },
    [AccountUpgradeLimitModalContext.UnlockSSO]: {
      title: 'Unlock Single Sign-On (SSO)',
      subtitle: `<span class="sm-text-base">Contact us to upgrade your subscription plan and enable SSO to streamline your login process. <a href="${this.ssoArticle}" class="sm-text-default-text hover:sm-text-default-text visited:sm-text-default-text sm-underline" target="_blank">Learn more</a></span>`,
      hasInnerHtmlSubtitle: true,
      imageSrc: '/assets/images/usage-limits/sso.svg',
      imageAlt: 'lock',
      eventContext: AccountUpgradeLimitModalContext.UnlockSSO,
    },
    [AccountUpgradeLimitModalContext.AdditionalWorkspaces]: {
      title: 'Unlock additional workspaces',
      subtitle: `<span class="sm-text-base">Contact us to upgrade your subscription plan and enable creation of additional Dashpivot workspaces</span>. <a href="${this.dashpivotWorkspaceArticle}" class="sm-text-default-blue sm-underline" target="_blank">Learn more</a></span>`,
      hasInnerHtmlSubtitle: true,
      imageSrc: '/assets/images/usage-limits/multiple-workspaces-in-an-account.svg',
      imageAlt: 'lock',
      eventContext: 'Unlock Additional Workspaces Banner',
      showLoadingSpinner: true,
      placeHolderHeight: 'sm-h-[220px]',
    },
  };

  constructor(
    private readonly modal: NgbModal,
    private readonly segmentService: SegmentService,
  ) {}

  openModal(context = AccountUpgradeLimitModalContext.DashpivotVisitorUsers) {
    const activeModal = this.modal.open(AccountUpgradeLimitComponent, {
      windowClass: 'modal-500',
      modalDialogClass: 'modal-dialog-top',
    });

    this.emitUpgradeClickedEvent(this.userContext[context].eventContext);

    activeModal.componentInstance.title = this.userContext[context].title;
    activeModal.componentInstance.hasInnerHtmlSubtitle = this.userContext[context].hasInnerHtmlSubtitle;
    activeModal.componentInstance.subtitle = this.userContext[context].subtitle;
    activeModal.componentInstance.imageSrc = this.userContext[context].imageSrc;
    activeModal.componentInstance.imageAlt = this.userContext[context].imageAlt;
    activeModal.componentInstance.showLoadingSpinner = this.userContext[context].showLoadingSpinner;
    activeModal.componentInstance.placeHolderHeight = this.userContext[context].placeHolderHeight;
  }

  private emitUpgradeClickedEvent(context: string) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.UpgradeClicked, { Context: context }),
      this.segmentService,
    );
  }
}
